span.multiselect-native-select {
  position: relative;
  width: 100%;
  select {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: (-1px) -1px -1px -3px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    left: 50%;
    top: 30px;
  }
}
//My-style
.multiselect-container .custom-control-indicator{
    position: relative;
    margin-right: 0.5rem;
}
/*.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
    position: relative;
    border: 1px solid #1e3e7d;
    background: #1e3e7d;
    &:before {
        position: absolute;
        top: 3px;
        left: 11px;
        display: block;
        width: 2px;
        height: 12px;
        content: "";
        transform: rotate(35deg);
        background: #fff;
    }
    &:after {
        position: absolute;
        top: 9px;
        left: 6px;
        display: block;
        width: 2px;
        height: 5px;
        content: "";
        transform: rotate(-45deg);
        background: #fff;
    }
}*/
//My-style-End
.multiselect-container {
  position: absolute;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  padding: 0 0 15px 0;
  &::-webkit-scrollbar {
    width: 2px;
    background-color: #edf0f5;
    padding-right: 10px;
  }
  &::-webkit-scrollbar-thumb{
    width: 2px;
    background-color: #1e3e7d;
    
  }
  li.multiselect-all {
    padding: 1rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    margin-bottom: 5px
  }
  .input-group {
    margin: 5px;
  }
  > li {
    padding: 0;
    > a.multiselect-all label {
      font-weight: 700;
    }
    &.multiselect-group label {
      margin: 0;
      padding: 5px 20px 5px 20px;
      height: 100%;
      font-weight: 700;
    }
    &.multiselect-group-clickable label {
      cursor: pointer;
    }
    > a {
      padding: 0;
      > label {
        margin: 0;
        height: 100%;
        cursor: pointer;
        font-weight: 400;
        padding: 3px 20px 3px 20px;
        &.radio, &.checkbox {
          margin: 0;
        }
        > input[type=checkbox] {
          margin-bottom: 5px;
        }
      }
    }
  }
}

.btn-group > .btn-group:nth-child(2) > .multiselect.btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.form-inline .multiselect-container {
  label {
    &.checkbox, &.radio {
      padding: 3px 20px 3px 40px;
    }
  }
  li a label {
    &.checkbox input[type=checkbox], &.radio input[type=radio] {
      margin-left: -20px;
      margin-right: 0;
    }
  }
}