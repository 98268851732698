.login-wrap {
  min-height: 100vh;
  background: url(../img/login/login-bg.jpg) no-repeat 50% 50% #895939;
  background-size: cover;
  box-sizing: border-box;
  padding-bottom: 50px;
}

.login-form {
  background: rgba(#b8b8b8, 0.52);
  padding: 20px;
  max-width: 500px;
  margin: 0 auto;

  @include media-breakpoint-up(sm){
    padding: 40px;
  }

  @include media-breakpoint-up(md){
    padding: 80px;
  }

  & .form-control {
    border: none;
  }

  & .input-group-addon{
    border: none;
    border-right: 1px solid #e6e6e6;
    background: #ffffff;
    color: #afafaf;
    text-align: center;
    width: 50px;
  }
}

.login-copy{
  position: absolute;
  top: 100%;
  margin-top: -50px;
  left: 0;
  width: 100%;
  color: rgba(#ffffff, 0.35);
  font-size: 0.9rem;
}