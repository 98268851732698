// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s or `<ul>`s.

.nav {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: $nav-link-padding;

  @include hover-focus {
    text-decoration: none;
  }

  // Disabled state lightens text and removes hover/tab effects
  &.disabled {
    color: $nav-disabled-link-color;
    cursor: $cursor-disabled;
  }
}


//
// Tabs
//

.nav-tabs {
  border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
  padding-left: 5px;

  .nav-item {
    margin-bottom: -$nav-tabs-border-width;
  }

  .nav-link {
    border: $nav-tabs-border-width solid transparent;
    //@include border-top-radius($nav-tabs-border-radius);
    color: $gray-light;
    font-size: $font-size-xs;
    padding: 1.25em 2.1em;
    position: relative;

    @include hover-focus {
      border-color: transparent transparent $blue;
    }

    &.disabled {
      color: $nav-disabled-link-color;
      background-color: transparent;
      border-color: transparent;
    }

    @include media-breakpoint-up(md){
      &:after{
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: -2px;
        margin: auto;
        width: $nav-tabs-border-width;
        height: 20px;
        background: $nav-tabs-active-link-hover-border-color;


        @at-root {
          .nav-item:last-child .nav-link:after {
            display: none;
          }
        }
      }
    }

  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $nav-tabs-active-link-hover-color;
    background-color: $nav-tabs-active-link-hover-bg;
    border-color: $nav-tabs-active-link-hover-border-color $nav-tabs-active-link-hover-border-color $nav-tabs-active-link-hover-bg;

    @include media-breakpoint-down(sm){
      border: transparent;
    }

    &:after{
      display: none;
    }
  }

  .dropdown-menu {
    // Make dropdown border overlap tab border
    margin-top: -$nav-tabs-border-width;
    // Remove the top rounded corners here since there is a hard edge above the menu
    @include border-top-radius(0);
  }
}


//
// Pills
//

.nav-pills {
  .nav-link {
    @include border-radius($nav-pills-border-radius);
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $nav-pills-active-link-color;
    cursor: default;
    background-color: $nav-pills-active-link-bg;
  }
}


//
// Justified variants
//

.nav-fill {
  .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
}

.nav-justified {
  .nav-item {
    flex: 1 1 100%;
    text-align: center;
  }
}


// Tabbable tabs
//
// Hide tabbable panes to start, show them when `.active`

.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}
