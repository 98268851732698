.header{
  border-bottom: 4px solid #ebebeb;


  @include media-breakpoint-up(lg){
    padding-bottom: 0.4rem;
  }

  & .navbar-toggler{
    top: 12px;
  }
}

.header-logout{
  border-left: 1px solid $gray-lighter;

  &:hover{
    text-decoration: none;
  }
}

.header-logo{
  width: 120px;

  @include media-breakpoint-up(lg){
    width: auto;
  }
}