header {

}

.boy{
  display: inline-block;
  @include sprite($boy)
}

.girl{
  display: inline-block;
  @include sprite($girl)
}