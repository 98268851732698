@import "awesome/font-awesome";
@import "bootstrap/bootstrap";
@import "bootstrap/bootstrap-multiselect";

@import "plugins/jquery-ui";
@import "plugins/plot";

@import "sprite";
@import "sprite_svg";

@import "variables";
@import "custom";


@import "blocks/l-header";
@import "blocks/login";
@import "blocks/footer";
@import "blocks/header";
@import "blocks/tablesorter";
@import "blocks/autocomplete";
@import "blocks/uploader";
@import "blocks/search";


/* TEST SPRITE */
.boy{
  display: inline-block;
  @include sprite($boy)
}
.girl{
  display: inline-block;
  @include sprite($girl)
}