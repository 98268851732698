.search-result{
  font-size: .75rem;
}

.search-modal {
  font-size: .75rem;
  position: absolute;
  background: #ffffff;
  box-shadow: 0 14px 35px rgba(0, 0, 0, 0.25);
  width: 430px;
  bottom: auto;
  right: auto;
  max-height: 450px;
  overflow-y: auto;

  @include media-breakpoint-down(md){
    width: 300px;
  }

  @include media-breakpoint-down(sm){
    width: 200px;
  }
}

.search-map{
  position: relative;

  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    display: none;
  }

  &.active {

    &:after{
      display: block;
    }
  }
}

.search-print{
  line-height: 40px;
  font-size: $font-size-xs;
  text-decoration: none;
}

.search-print-ico{
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  color: #d1d1d1;
  border: 1px solid;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}