// Horizontal dividers
//
// Dividers (basically an hr) within dropdowns and nav lists

@mixin nav-divider($color: #e5e5e5) {
  height: 1px;
  margin: ($spacer-y / 2) 0;
  overflow: hidden;
  background-color: $color;
}
