body{
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 50px;
  position: relative;

  &.login-page{
    padding: 0;
  }
}

.container-fluid,
.header .navbar{
  max-width: 984px;
  margin-left: auto;
  margin-right: auto;
}
//My-style

.btn-primary{
  background-color: #1e3e7d;
}
.custom-control-indicator{
  border-color: #636c72;  
}
.collapse.show{
  background: #f0f1f5;
  &:hover{
    font-weight: 500;
    }
  ul{
    list-style: none;      
    }
}
.header .nav-item:last-child .nav-link:after{
  display: block;      
}
.rounded-circle{
    border-radius: 50% !important;
}
.pagination .page-link{
    padding: 0.2rem 0.55rem; 
}