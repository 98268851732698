.autocomplete-wrapper{
  display: block;
  position: relative;

  & > i {
    position: absolute;
    top: 14px;
    right: 12px;
    font-size: 0.85rem;
    cursor: text;
  }
}

.autocomplete-input{
  color: $blue;
  font-size: $font-size-xs;
  font-weight: 600;
  height: 40px;
  font-family: $font-openSans;
  padding: 0.5rem 1.75rem 0.5rem 0.75rem;

  &.is-open{
    border-radius: $input-border-radius $input-border-radius 0 0;
  }

  &:focus{
    border: $input-btn-border-width solid $input-border-color;
  }
}

.autocomplete-list{

  &.ui-widget-content{
    border: $input-btn-border-width solid $input-border-color;
    border-top: none;
    border-radius: 0 0 $input-border-radius $input-border-radius;
    font-family: $font-openSans;
  }

  & > li {
    padding: .5rem 1rem;
    font-size: $font-size-xs;
    color: $gray-light;
    font-weight: 600;

    &:hover{
      color: $white;
      background: $blue;
    }

    &:last-child{
      border-radius: 0 0 $input-border-radius $input-border-radius;
    }
  }
}