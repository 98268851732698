.tablesorter-icon-wrapper{
  margin-left: 2px;

  & > i {
    position: relative;
    font-size: 0.85rem;
    color: #afafaf;

    &:first-child{
      top: -3px;

      .tablesorter-headerAsc & {
        color: $blue;
      }
    }

    &:last-child{
      top: 3px;

      .tablesorter-headerDesc & {
        color: $blue;
      }
    }
  }
}