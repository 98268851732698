.uploader-wrapper{
  position: relative;
  background-color: $input-bg;
  font-size: $font-size-base;
  border: $input-btn-border-width solid $input-border-color;
  border-radius: $input-border-radius;
  height: 2.5rem;

  input[type='file']{
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}

.uploader-btn{
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
}

.uploader-result{
  line-height: 2.5rem;
  white-space: nowrap;
  max-width: calc(100% - 8rem);
  overflow: hidden;
  display: block;
}

.uploader-lbl{
  color: #808080;
  font-size: $font-size-xs;
}