/*=================================
=            VARIABLES            =
=================================*/

$font-merriweather: 'Merriweather', serif;
$font-openSans: 'Open Sans', sans-serif;

.font-merriweather{
  font-family: $font-merriweather;
}

